<template>
  <div class="filter">
    <div class="filter-block container flex">
      <ul class="filter__items flex">
        <li class="filter__item search">
          <input
              v-model="inputSearch"
              class="filter__item-select search-input"
              type="text"
              placeholder="Поиск ..."
              @input="filterDatabase()"
          />
          <img class="search-icon" src="@/assets/png/search-icon.png" alt="" />
        </li>
        <li class="filter__item">
          <b-form-select
              v-model="category"
              class="filter__item-select main__select"
              :options="listCategories"
              text-field="ru"
              value-field="name"
              @input="filterDatabase()"
          />
          <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
        </li>
        <li class="filter__item">
          <b-form-select
              v-model="name"
              class="filter__item-select main__select"
              :options="listNames"
              text-field="ru"
              value-field="name"
              @input="filterDatabase()"
          />
          <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
        </li>
        <li class="filter__item">
          <b-form-select
              v-model="condition"
              class="filter__item-select main__select"
              :options="listConditions"
              text-field="ru"
              value-field="name"
              @input="filterDatabase()"
          />
          <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
        </li>
        <li class="filter__item sub-header__search-block right">
          <button
              class="header__btn right-item default-btn"
              @click="exportProducts()"
          >
            <img src="@/assets/svg/export.svg" alt="">
            Экспорт данных
          </button>
        </li>
      </ul>
      <slot name="logbook" class=""></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      category: '',
      address: null,
      inputSearch: '',
      name: '',
      condition: '',
      listCategories: [
        { name: '', ru: 'Категория запчасти' },
        { name: 'service', ru: 'Услуга' },
        { name: 'product', ru: 'Товар' },
      ],
      listNames: [
        { name: '', ru: 'Наименование' },
        { name: 'service', ru: 'Услуга' },
        { name: 'product', ru: 'Товар' },
      ],
      listConditions: [
        { name: '', ru: 'Состояние' },
        { name: 'second_hand', ru: 'Б/У' },
        { name: 'new', ru: 'Новое' },
      ],
    };
  },
  methods: {
    exportProducts() {

    },
    filterDatabase() {
      this.$store.commit("textFilter", this.inputSearch);
      this.$store.commit("dateFilter", null);
    },
  },
  computed: {
    ...mapState(["textFilter", "dateFilter", "typeFilter"]),
  }

};
</script>

<style scoped>
.filter {
  padding: 10px 0 10px 0;
  background-color: #fff;
  border-top: 1px solid #f2f2f8;
  position: relative;
}
.filter__items {
  justify-content: flex-start;
  flex-wrap: wrap;
}
.filter__item {
  position: relative;
  font-size: 14px;
  margin-top: 10px;
}
.filter__item:not(:last-child) {
  margin-right: 30px;
}
/*.filter__search {*/
/*  max-width: 100%;*/
/*  color: #acacac;*/
/*}*/
.filter__item-select {
  /* background: #FFFFFF; */
  border: 1px solid #e0e9fa;
  border-radius: 8px;
  color: #707070;
  width: 260px;
}
.main__select-icon {
  top: 50%;
}
.sub-header__search-block {
  position: relative;
  /* margin: 0px 0 0 38px; */
  height: 40px;
}
</style>